import { lazy } from "react";

const ProductOptionsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/productOptions/:product_id",
            component: lazy(() => import("./ProductOptions")),
        },
        {
            path: "/setup/addProductOption/:product_id",
            component: lazy(() => import("./AddProductOption")),
        },
        {
            path: "/setup/editProductOption/:product_id/:id",
            component: lazy(() => import("./EditProductOption")),
        },
    ],
};

export default ProductOptionsPageConfig;
