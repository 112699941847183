import { lazy } from "react";

const StorePlacesPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/storePlaces/:store_id?",
            component: lazy(() => import("./StorePlaces")),
        },
        {
            path: "/setup/addStorePlace/:store_id?",
            component: lazy(() => import("./AddStorePlace")),
        },
        {
            path: "/setup/editStorePlace/:store_id?/:id",
            component: lazy(() => import("./EditStorePlace")),
        },
    ],
};

export default StorePlacesPageConfig;
