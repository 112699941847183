import { lazy } from "react";

const OptionValuesPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/optionValues/:option_id",
            component: lazy(() => import("./OptionValues")),
        },
        {
            path: "/setup/addOptionValue/:option_id",
            component: lazy(() => import("./AddOptionValue")),
        },
        {
            path: "/setup/editOptionValue/:option_id/:id",
            component: lazy(() => import("./EditOptionValue")),
        },
        {
            path: "/setup/importOptionValues/:option_id",
            component: lazy(() => import("./Import")),
        }
    ],
};

export default OptionValuesPageConfig;
