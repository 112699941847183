import { lazy } from "react";

const SettingsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/settings",
            component: lazy(() => import("./Settings")),
        },
    ],
};

export default SettingsPageConfig;
