import { lazy } from "react";

const TaxesPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/taxes/:store_id?/:place_id?/:payment_method_id?/:delivery_company_id?/:product_id?",
            component: lazy(() => import("./taxes")),
        },
        {
            path: "/setup/addTax",
            component: lazy(() => import("./AddTax")),
        },
        {
            path: "/setup/editTax/:id",
            component: lazy(() => import("./EditTax")),
        },
    ],
};

export default TaxesPageConfig;
