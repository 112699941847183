import { lazy } from "react";

const HelpSectionsConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/HelpSections",
            component: lazy(() => import("./HelpSections")),
        },
        {
            path: "/setup/addHelpSection",
            component: lazy(() => import("./AddHelpSection")),
        },
        {
            path: "/setup/editHelpSection/:id",
            component: lazy(() => import("./EditHelpSection")),
        },
    ],
};

export default HelpSectionsConfig;
