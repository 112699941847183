import { lazy } from "react";

const StoreUsersPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/storeUsers/:type?/:store_id?",
            component: lazy(() => import("./StoreUsers")),
        },
        {
            path: "/setup/addStoreUser/:type?/:store_id?",
            component: lazy(() => import("./AddUser")),
        },
        {
            path: "/setup/editStoreUser/:type?/:store_id?/:id",
            component: lazy(() => import("./EditUser")),
        },
    ],
};

export default StoreUsersPageConfig;
