import { lazy } from "react";

const StoresPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/stores/:parent_id?/:place_id?",
            component: lazy(() => import("./Stores")),
        },
        {
            path: "/setup/addStore/:parent_id?/:place_id?",
            component: lazy(() => import("./AddStore")),
        },
        {
            path: "/setup/editStore/:id?/:place_id?",
            component: lazy(() => import("./EditStore")),
        },
    ],
};

export default StoresPageConfig;
