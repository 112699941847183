import { lazy } from "react";

const PublicPagesPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/publicPages",
            component: lazy(() => import("./PublicPages")),
        },
        {
            path: "/setup/addPublicPage",
            component: lazy(() => import("./AddPublicPage")),
        },
        {
            path: "/setup/editPublicPage/:id",
            component: lazy(() => import("./EditPublicPage")),
        },
    ],
};

export default PublicPagesPageConfig;
