import { lazy } from "react";

const AdsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/ads",
            component: lazy(() => import("./Ads")),
        },
        {
            path: "/setup/addAd",
            component: lazy(() => import("./AddAd")),
        },
        {
            path: "/setup/editAd/:id",
            component: lazy(() => import("./EditAd")),
        },
    ],
};

export default AdsPageConfig;
