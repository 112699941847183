import { lazy } from "react";

const SlidersPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/Sliders",
            component: lazy(() => import("./Sliders")),
        },
        {
            path: "/setup/addSlider",
            component: lazy(() => import("./AddSlider")),
        },
        {
            path: "/setup/editSlider/:id",
            component: lazy(() => import("./EditSlider")),
        },
    ],
};

export default SlidersPageConfig;
