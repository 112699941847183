import { lazy } from 'react';

const LanguagesPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/setup/languages',
      component: lazy(() => import('./LanguagesApp')),
      
    },
    {
      path: '/setup/addLanguages',
      component: lazy(() => import('./LanguagesAdd')),
      
    },
    {
      path: '/setup/editLanguages/:id?',
      component: lazy(() => import('./LanguagesEdit')),
      
    },
  ],
};

export default LanguagesPageConfig;
