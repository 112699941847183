import { lazy } from "react";

const PaymentMethodsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/paymentMethods",
            component: lazy(() => import("./PaymentMethods")),
        },
        {
            path: "/setup/addPaymentMethod",
            component: lazy(() => import("./AddPaymentMethod")),
        },
        {
            path: "/setup/editPaymentMethod/:id",
            component: lazy(() => import("./EditPaymentMethod")),
        },
    ],
};

export default PaymentMethodsPageConfig;
