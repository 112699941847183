import { lazy } from "react";

const CategoriesPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/Categories/:parent_id?",
            component: lazy(() => import("./Categories")),
        },
        {
            path: "/setup/addCategory/:parent_id?",
            component: lazy(() => import("./AddCategory")),
        },
        {
            path: "/setup/editCategory/:id?",
            component: lazy(() => import("./EditCategory")),
        },
    ],
};

export default CategoriesPageConfig;
