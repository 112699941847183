import { lazy } from "react";

const UsersPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/users/:type?",
            component: lazy(() => import("./Users")),
        },
        {
            path: "/setup/addUser/:type?",
            component: lazy(() => import("./AddUser")),
        },
        {
            path: "/setup/editUser/:type?/:id",
            component: lazy(() => import("./EditUser")),
        },
    ],
};

export default UsersPageConfig;
