import { lazy } from "react";

const AppIntroPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/appIntro",
            component: lazy(() => import("./AppIntro")),
        },
        {
            path: "/setup/addAppIntro",
            component: lazy(() => import("./AddAppIntro")),
        },
        {
            path: "/setup/editAppIntro/:id",
            component: lazy(() => import("./EditAppIntro")),
        },
    ],
};

export default AppIntroPageConfig;
