import { lazy } from "react";

const BrandsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/brands",
            component: lazy(() => import("./Brands")),
        },
        {
            path: "/setup/addBrand",
            component: lazy(() => import("./AddBrand")),
        },
        {
            path: "/setup/editBrand/:id",
            component: lazy(() => import("./EditBrand")),
        },
    ],
};

export default BrandsPageConfig;
