import { lazy } from "react";

const CouponsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/coupons",
            component: lazy(() => import("./Coupons")),
        },
        {
            path: "/setup/addCoupon",
            component: lazy(() => import("./AddCoupons")),
        },
        {
            path: "/setup/editCoupon/:id",
            component: lazy(() => import("./EditCoupon")),
        },
    ],
};

export default CouponsPageConfig;
