import { lazy } from "react";

const BenefitsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/benefits",
            component: lazy(() => import("./benefits")),
        },
        {
            path: "/setup/addBenefit",
            component: lazy(() => import("./AddBenefit")),
        },
        {
            path: "/setup/editBenefit/:id",
            component: lazy(() => import("./EditBenefit")),
        },
    ],
};

export default BenefitsPageConfig;
