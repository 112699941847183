import { lazy } from 'react';

const PlacesPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/setup/places/:parent_id?',
      component: lazy(() => import('./PlacesApp')),
      
    },
    {
      path: '/setup/addPlaces/:parent_id?',
      component: lazy(() => import('./PlacesAdd')),
      
    },
    {
      path: '/setup/editPlaces/:id?',
      component: lazy(() => import('./PlacesEdit')),
      
    },
  ],
};

export default PlacesPageConfig;
