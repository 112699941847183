import { lazy } from "react";

const SocialMediaPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/SocialMedia",
            component: lazy(() => import("./SocialMedia")),
        },
        {
            path: "/setup/addMedia",
            component: lazy(() => import("./AddMedia")),
        },
        {
            path: "/setup/editMedia/:id",
            component: lazy(() => import("./EditMedia")),
        },
    ],
};

export default SocialMediaPageConfig;
