import { lazy } from "react";

const LabelsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/labels",
            component: lazy(() => import("./Labels")),
        },
        {
            path: "/setup/addLabel",
            component: lazy(() => import("./AddLabel")),
        },
        {
            path: "/setup/editLabel/:id",
            component: lazy(() => import("./EditLabel")),
        },
    ],
};

export default LabelsPageConfig;
