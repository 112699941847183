import { lazy } from "react";

const CouponRestrictionsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/couponRestrictions/:coupon_id?",
            component: lazy(() => import("./CouponRestrictions")),
        },
        {
            path: "/setup/addCouponRestriction/:coupon_id?",
            component: lazy(() => import("./AddCouponRestriction")),
        },
        {
            path: "/setup/editCouponRestriction/:coupon_id?/:id",
            component: lazy(() => import("./EditCouponRestriction")),
        },
    ],
};

export default CouponRestrictionsPageConfig;
