import { lazy } from "react";

const CommissionsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/commissions/:store_id?/:place_id?/:payment_method_id?/:delivery_company_id?/:product_id?",
            component: lazy(() => import("./Commissions")),
        },
        {
            path: "/setup/addCommission",
            component: lazy(() => import("./AddCommission")),
        },
        {
            path: "/setup/editCommission/:id",
            component: lazy(() => import("./EditCommission")),
        },
    ],
};

export default CommissionsPageConfig;
