import { lazy } from "react";

const ProductSpecificationPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/productSpecifications/:product_id?",
            component: lazy(() => import("./ProductSpecifications")),
        },
        {
            path: "/setup/addProductSpecification/:product_id?",
            component: lazy(() => import("./AddProductSpecification")),
        },
        {
            path: "/setup/editProductSpecification/:product_id?/:id",
            component: lazy(() => import("./EditProductSpecification")),
        },
    ],
};

export default ProductSpecificationPageConfig;
