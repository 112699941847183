import { lazy } from "react";

const ProductImagesPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/productImages/:product_id?",
            component: lazy(() => import("./ProductImages")),
        },
        {
            path: "/setup/addProductImage/:product_id?",
            component: lazy(() => import("./AddProductImage")),
        },
        {
            path: "/setup/editProductImage/:product_id?/:id",
            component: lazy(() => import("./EditProductImage")),
        },
    ],
};

export default ProductImagesPageConfig;
