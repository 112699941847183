import { lazy } from "react";

const GuidesPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/guides",
            component: lazy(() => import("./Guides")),
        },
        {
            path: "/setup/addGuide",
            component: lazy(() => import("./AddGuide")),
        },
        {
            path: "/setup/editGuide/:id",
            component: lazy(() => import("./EditGuide")),
        },
    ],
};

export default GuidesPageConfig;
