import { lazy } from "react";

const StoreSettlementsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/storeSettlements/:store_id?",
            component: lazy(() => import("./StoreSettlements")),
        },
        {
            path: "/setup/addStoreSettlement/:store_id?",
            component: lazy(() => import("./AddStoreSettlement")),
        },
    ],
};

export default StoreSettlementsPageConfig;
