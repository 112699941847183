import { lazy } from "react";

const CampaignsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/campaigns",
            component: lazy(() => import("./Campaigns")),
        },
        {
            path: "/setup/addCampaign",
            component: lazy(() => import("./AddCampaign")),
        },
        {
            path: "/setup/editCampaign/:id",
            component: lazy(() => import("./EditCampaigns")),
        },
    ],
};

export default CampaignsPageConfig;
