import { lazy } from "react";

const CollectionsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/collections",
            component: lazy(() => import("./Collections")),
        },
        {
            path: "/setup/addCollection",
            component: lazy(() => import("./AddCollection")),
        },
        {
            path: "/setup/editCollection/:id",
            component: lazy(() => import("./EditCollection")),
        },
    ],
};

export default CollectionsPageConfig;
