import { lazy } from "react";

const StoreStatementPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/storeStatement/:store_id?",
            component: lazy(() => import("./StoreStatement")),
        },
    ],
};
export default StoreStatementPageConfig;
