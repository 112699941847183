import { lazy } from "react";

const ProductRelatedPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/productRelated/:product_id?",
            component: lazy(() => import("./ProductRelated")),
        },
        {
            path: "/setup/addProductRelated/:product_id?",
            component: lazy(() => import("./AddProductRelated")),
        },
    ],
};

export default ProductRelatedPageConfig;
